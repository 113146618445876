import type { FC } from 'react';
import { useEffect, useState } from 'react';
import {
    Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    List, ListItemButton, ListItemText,
    Button, FormControlLabel, Checkbox
} from '@mui/material';
import { useAPI } from '../lib/useAPI';
import { type components } from '../spec/schema';
import Pagination from '@mui/material/Pagination';

type Props = {
    open: boolean;
    setOpen: Function;
    setOrganizations: Function; // 複数の組織を選択できるように変更
};

export const OrganizationMultiDialog: FC<Props> = ({
    open,
    setOpen,
    setOrganizations
}) => {
    const [page, setPage] = useState(1);
    const perPage = 10;
    const [totalPage, setTotalPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [list, setList] = useState<components['schemas']['OrganizationSchema'][]>([]);
    const { apiRequest } = useAPI();
    const [selectedOrgs, setSelectedOrgs] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [viewableOrganizations, setViewableOrganizations] = useState<components['schemas']['OrganizationSchema'][]>([]);
    const [isFirstOpen, setIsFirstOpen] = useState(true);
    const [shouldResetSelection, setShouldResetSelection] = useState(false);
    const [initialOpen, setInitialOpen] = useState(false);

    const handleClose = () => {
        const selectedOrganizations = viewableOrganizations.filter(org => selectedOrgs.includes(org.id));
        setOrganizations(selectedOrganizations);
        setOpen(false);
    };

    // handleCancel 関数を削除

    // loadList関数を削除

    // loadAllOrganizations関数を削除

    useEffect(() => {
        if (open && !initialOpen) {
            setInitialOpen(true);
            if (isFirstOpen) {
                // 初回オープン時の処理
                const loadUserInfo = async () => {
                    try {
                        const response = await apiRequest('get', '/api/user/info');
                        if (response.viewable_organizations) {
                            setViewableOrganizations(response.viewable_organizations);
                            setTotal(response.viewable_organizations.length);
                            setTotalPage(Math.ceil(response.viewable_organizations.length / perPage));

                            // 初期状態で全ての組織を選択
                            const orgIds = response.viewable_organizations.map((org: any) => org.id);
                            setSelectedOrgs(orgIds);
                            setOrganizations(response.viewable_organizations);
                            setSelectAll(true);
                        }
                    } catch (error) {
                        console.error('ユーザー情報の取得に失敗しました:', error);
                    }
                };

                loadUserInfo();
                setIsFirstOpen(false);
            } else if (selectedOrgs.length === 0 && viewableOrganizations.length > 0) {
                // 2回目以降のオープン時、選択された組織が0の場合のみリセット
                setSelectAll(true);
                setSelectedOrgs(viewableOrganizations.map(org => org.id));
                setOrganizations(viewableOrganizations);
            }
        } else if (!open) {
            setInitialOpen(false);
        }
    }, [open, apiRequest, perPage, isFirstOpen, viewableOrganizations, selectedOrgs, setOrganizations, initialOpen]);

    useEffect(() => {
        if (shouldResetSelection) {
            setSelectAll(true);
            setSelectedOrgs(viewableOrganizations.map(org => org.id));
            setOrganizations(viewableOrganizations);
            setShouldResetSelection(false);
        }
    }, [shouldResetSelection, viewableOrganizations, setOrganizations]);

    useEffect(() => {
        if (viewableOrganizations.length > 0) {
            const startIndex = (page - 1) * perPage;
            const endIndex = startIndex + perPage;
            setList(viewableOrganizations.slice(startIndex, endIndex));
        }
    }, [page, viewableOrganizations]);

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }

    const handleToggle = (id: number) => {
        const currentIndex = selectedOrgs.indexOf(id);
        const newChecked = [...selectedOrgs];

        if (currentIndex === -1) {
            newChecked.push(id);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedOrgs(newChecked);
    };

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        if (checked) {
            setSelectedOrgs(viewableOrganizations.map(org => org.id));
            setOrganizations(viewableOrganizations);
        } else {
            setSelectedOrgs([]);
            setOrganizations([]);
        }
    };

    useEffect(() => {
        const allSelected = selectedOrgs.length === viewableOrganizations.length && viewableOrganizations.length > 0;
        setSelectAll(allSelected);
    }, [selectedOrgs, viewableOrganizations]);

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>組織の選択</DialogTitle>
            <DialogContent className="ellipsis500" sx={{ pb: 0 }}>
                <DialogContentText sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                    組織を選択してください（複数選択可）
                </DialogContentText>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            indeterminate={selectedOrgs.length > 0 && selectedOrgs.length < list.length}
                        />
                    }
                    label="全て選択"
                />

                <List>
                    {list.map((e) => (
                        <ListItemButton key={e.id} onClick={() => handleToggle(e.id)}>
                            <Checkbox
                                edge="start"
                                checked={selectedOrgs.indexOf(e.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                            <ListItemText
                                primary={e.name}
                                primaryTypographyProps={{
                                    fontSize: { xs: '0.875rem', sm: '1rem' },
                                }}
                                className='ellipsis200'
                            />
                        </ListItemButton>
                    ))}
                </List>

                <Box sx={{ display: { xs: 'block', sm: 'flex' }, textAlign: { xs: 'center' }, mt: 2, justifyContent: 'center', width: '100%' }}>
                    <Pagination
                        showFirstButton
                        showLastButton
                        count={totalPage}
                        page={page} // 現在のページ
                        onChange={handlePageChange}
                        sx={{ display: { xs: 'flex' }, justifyContent: 'center', mb: { xs: 1, sm: 0 } }}
                    />
                    全 {total}件
                </Box>
            </DialogContent>
            <DialogActions>
                未選択時は全選択になります
                <Button onClick={handleClose}>閉じる</Button>
            </DialogActions>
        </Dialog>
    );
}
