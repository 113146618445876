import { useState } from 'react';
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from 'react-router-dom';

import GroupsIcon from '@mui/icons-material/Groups';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import WavesIcon from '@mui/icons-material/Waves';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PublicIcon from '@mui/icons-material/Public';
import { Link } from 'react-router-dom';
import { useSanctum } from 'react-sanctum'
import styles from "./MyHeader.module.css";
import {
    Box, Drawer, List, ListItem, ListItemText, Divider, IconButton, Button
} from '@mui/material';
import { OrganizationMultiDialog } from '../components/OrganizationMultiDialog'
import { type components } from '../spec/schema';

type MyHeaderTopProps = {
    setSelectedOrganizations: React.Dispatch<React.SetStateAction<components['schemas']['OrganizationSchema'][]>>;
};

const HamburgerBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '3px',
    right: '10px',
}));

const HamburgerButton = styled(IconButton)(({ theme }) => ({
    padding: '3px',
    '& svg': {
        width: '24px',
        height: '28px',
        color: '#ffffff',
    }
}));

export const MyHeaderTop: React.FC<MyHeaderTopProps> = ({ setSelectedOrganizations }) => {
    const { authenticated, user } = useSanctum();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [orgOpen, setOrgOpen] = useState(false);
    const location = useLocation();
    const toggleDrawerOpen = () => {
        setDrawerOpen(!drawerOpen);
    }
    const navigate = useNavigate();

    const isButtonDisabled = !["/", "/map", "/building-sensor","/building-sensor/eew"].includes(location.pathname);
    
    return (
        <>
            {/* PCヘッダ */}
            <div className={styles.myHeaderTop}>
                {authenticated &&
                    <>
                        <Box className={styles.pcHeaderText + " ellipsis400"} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', alignItems: 'top' }}><span className="ellipsis200">{user.name}</span><span>&nbsp;/&nbsp;</span><span className="ellipsis300">{user.organization.name}</span></Box>
                        <Box className={styles.pcHeaderText + " ellipsis150"} sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-between', alignItems: 'top' }}>
                            <Box sx={{ width: 'auto',pr: 1 }}>
                                <Link
                                    to="/"
                                    reloadDocument={true}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Button 
                                        variant="contained" 
                                        fullWidth
                                        sx={{ 
                                            backgroundColor: '#e5e5e5',
                                            color: '#333333',
                                            mr: 3,
                                            '&:hover': {
                                                backgroundColor: '#909090',
                                            },
                                        }} 
                                        className={styles.menuLogoutButtonText}
                                    >
                                        トップ
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                    </>
                }
                <Box className={styles.pcHeaderText + " ellipsis400"} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', alignItems: 'top' }}>
                    <div className={styles.menuLogoutButton}>
                        <Button 
                            variant="text" 
                            sx={{ 
                                color: { md: '#333333', xs: '#ffffff' }, 
                                m: 0,
                                '&.Mui-disabled': {
                                    color: { md: '#999999', xs: '#999999' }
                                }
                            }} 
                            className={styles.menuLogoutButtonText} 
                            onClick={() => setOrgOpen(true)}
                            disabled={isButtonDisabled}
                        >
                            <GroupsIcon sx={{ color: { md: '#333333', xs: '#ffffff' }, width: { xs: '20px', md: '24px' }, height: { xs: '20px', md: '24px' }, ml: '3px', mr: '3px' }} />
                            表示する組織
                        </Button>
                    </div>
                    <Link to="/logout" className={styles.menuLogoutButton}>
                        <Button variant="text" sx={{ color: { md: '#333333', xs: '#ffffff' }, m: 0 }} className={styles.menuLogoutButtonText}>
                            <LogoutIcon sx={{ color: { md: '#333333', xs: '#ffffff' }, width: { xs: '20px', md: '24px' }, height: { xs: '20px', md: '24px' }, ml: '3px', mr: '3px' }} />
                            ログアウト
                        </Button>
                    </Link>
                </Box>
                <HamburgerBox sx={{ display: { xs: 'block', md: 'none' } }}>
                    <HamburgerButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawerOpen}
                    >
                        <MenuIcon />
                    </HamburgerButton>
                </HamburgerBox>
            </div >
            <Drawer sx={{ width: 300, flexShrink: 0 }} anchor="right" open={drawerOpen} variant="temporary" onClose={toggleDrawerOpen}>
                <List>
                    <ListItem button onClick={toggleDrawerOpen}>
                        <ListItemIcon>
                            <CloseIcon />
                        </ListItemIcon>
                        <ListItemText primary="閉じる" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem 
                        button 
                        href="" 
                        onClick={() => setOrgOpen(true)}
                        disabled={isButtonDisabled}
                        sx={{
                            '&.Mui-disabled': {
                                opacity: 0.5,
                            }
                        }}
                    >
                        <ListItemIcon>
                            <GroupsIcon sx={{ color: isButtonDisabled ? '#999999' : 'inherit' }} />
                        </ListItemIcon>
                        <ListItemText primary="表示する組織" sx={{ color: isButtonDisabled ? '#999999' : 'inherit' }} />
                    </ListItem>
                    <ListItem button href="" onClick={() => { navigate("/map"); toggleDrawerOpen(); }}>
                        <ListItemIcon>
                            <PublicIcon />
                        </ListItemIcon>
                        <ListItemText primary="マップ" />
                    </ListItem>
                    <ListItem button href="" onClick={() => { navigate("/eew"); toggleDrawerOpen(); }}>
                        <ListItemIcon>
                            <WavesIcon />
                        </ListItemIcon>
                        <ListItemText primary="地震情報" />
                    </ListItem>
                    <ListItem button href="" onClick={() => { navigate("/building-sensor"); toggleDrawerOpen(); }}>
                        <ListItemIcon>
                            <ApartmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="建物地震計" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate("/setting"); toggleDrawerOpen(); }}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="設定" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate("/logout"); toggleDrawerOpen(); }}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText 
                            primary="ログアウト"
                            secondary={<><span className="ellipsis150">{user.name}</span> / <span className="ellipsis75">{user.organization.name}</span></>}
                            secondaryTypographyProps={{ sx: { opacity: 0.7 } }}
                        />
                    </ListItem>
                </List>
                <Divider />
            </Drawer>
            <OrganizationMultiDialog
                open={orgOpen}
                setOpen={setOrgOpen}
                setOrganizations={setSelectedOrganizations}
            />
        </>
    );
}
